<template>
	<b-container fluid class="px-0">
		<b-row class="w-100">
			<b-col v-if="level == 'user'" cols="12">
				<h3 style="margin: 0">{{ this.FormMSG(14, 'Your Personal Overview') }}</h3>
			</b-col>
			<b-col v-if="level != 'user'" cols="12">
				<h3 style="margin: 0; margin-bottom: 12px">{{ this.FormMSG(13, 'Project overview') }}</h3>
			</b-col>
			<b-col cols="12">
				<b-card class="kpi-card">
					<b-card-body class="kpi-card-body">
						<b-container fluid>
							<b-row>
								<b-col v-if="level != 'user'" cols="12" md="4">
									<span>{{ this.FormMSG(10, 'Total expenses:') }} </span>
									<strong>{{ formattedKPIS.expenses }}</strong>
								</b-col>
								<b-col v-if="level == 'user'" cols="12" md="4">
									<span>{{ this.FormMSG(15, 'Expenses:') }} </span>
									<strong>{{ formattedKPIS.expenses }}</strong>
								</b-col>
								<!--v-on:click="navigateToPath('/validateExp')"-->
								<b-col v-if="level != 'user'" cols="12" md="4">
									<span>{{ this.FormMSG(11, 'Total Purchase Orders:') }}</span>
									<strong>{{ formattedKPIS.pos }}</strong>
								</b-col>
								<b-col v-if="level == 'user'" cols="12" md="4">
									<span>{{ this.FormMSG(16, 'Purchase Orders:') }}</span>
									<strong>{{ formattedKPIS.pos }}</strong>
								</b-col>

								<b-col v-if="level != 'user'" cols="12" md="4">
									<span>{{ this.FormMSG(12, 'Total Salaries:') }}</span>
									<strong>{{ formattedKPIS.salaries }}</strong>
								</b-col>
								<b-col v-if="level == 'user'" cols="12" md="4">
									<span>{{ this.FormMSG(17, 'Salary:') }}</span>
									<strong>{{ formattedKPIS.salaries }}</strong>
								</b-col>
							</b-row>
						</b-container>
					</b-card-body>
				</b-card>
			</b-col>
		</b-row>
	</b-container>
</template>

<script>
import gql from 'graphql-tag';
import numeral from 'numeral';
import languageMessages from '@/mixins/languageMessages';
import { store } from '@/store';

const queryKeys = {
	GetUserDashboard: {
		expenses: 'myTotalExpenses',
		salaries: 'myTotalSalary',
		pos: 'myTotalPurchaseOrders',
		filter: ''
	},
	GetAdminDashboard: {
		expenses: 'totalProjectExpenses',
		pos: 'totalProjectPurchaseOrders',
		salaries: 'totalProjectSalaries',
		filter: ''
	}
};
// const queryParams = {
// 	GetUserDashboard: {
// 		param: 'myTotalExpenses'
// 	},
// 	GetAdminDashboard: {
// 		expenses: 'totalProjectExpenses',
// 		pos: 'totalProjectPurchaseOrders',
// 		salaries: 'totalProjectSalaries'
// 	}
// };
export default {
	name: 'ProjectKpis',
	mixins: [languageMessages],
	props: {
		level: {
			type: String
		}
	},
	apollo: {
		mainKpis: {
			query() {
				// console.log("mainKpis this.level", this.level);
				const queryName = this.level === 'user' ? 'GetUserDashboard' : 'GetAdminDashboard';
				// console.log("inquery");

				return gql`query {

          ${queryName} {
            ${queryKeys[queryName].expenses}
            ${queryKeys[queryName].pos}
            ${queryKeys[queryName].salaries}

          }
        }`;
			},
			update: function (data) {
				const queryName = this.level === 'user' ? 'GetUserDashboard' : 'GetAdminDashboard';
				const { pos, expenses, salaries } = queryKeys[queryName];
				return {
					pos: data[queryName][pos],
					expenses: data[queryName][expenses],
					salaries: data[queryName][salaries]
				};
			},
			fetchPolicy: 'no-cache'
		}
	},
	data: function () {
		return {
			mainKpis: {}
		};
	},
	computed: {
		formattedKPIS() {
			// console.log("MAINKPIS:",this.mainKpis);
			const currencySymbol = (store && store.state && store.state.myProfile && store.state.myProfile.currencySymbol) || '€';
			if (Object.keys(this.mainKpis).length === 0) {
				return {};
			}

			return {
				expenses: `${currencySymbol} ${numeral(this.mainKpis.expenses).format('0,0.00')}`,
				pos: `${currencySymbol} ${numeral(this.mainKpis.pos).format('0,0.00')}`,
				salaries: `${currencySymbol} ${numeral(this.mainKpis.salaries).format('0,0.00')}`
			};
		}
	}
};
</script>

<style scoped>
.kpi-card {
	width: 100%;
	font-weight: bold;
	padding: 0;
}
.kpi-card-body {
	padding: 2rem 0;
}
.kpi-card-body span {
	color: #007949;
}
.kpi-card-body strong {
	display: block;
	font-size: 32px;
	font-weight: normal;
}
</style>
